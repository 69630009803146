<template>
  <div>
    <div style="display: flex; flex-direction: row; margin: 20px 0 20px 0">
<!--      <el-button :disabled="selection.length < 1" type="primary" @click="clearSelection">清除选择</el-button>-->
      <el-button :disabled="selection.length < 1" type="danger" @click="deleteSelection">{{ t('alarmManage.buttonDeleteSelection') }}</el-button>
      <el-button @click="deleteInvalid">{{ t('alarmManage.buttonDeleteInvalid') }}</el-button>
    </div>
    <el-table ref="tableRef" :data="tableData" row-key="id" max-height="800px"
              @selection-change="handleSelectionChange">
      <!--      <el-table-column label="id" prop="id" width="80" />-->
      <el-table-column type="selection" width="55"/>
      <el-table-column :label="t('alarmManage.warningCableAndBox')" prop="cableAndBox"/>
      <el-table-column :label="t('alarmManage.warningDevice')" prop="name"/>
      <el-table-column :label="t('alarmManage.warningItem')">
        <template #default="{ row }">
          {{ store.state.user.lang === 'zh' ? row.valueLabel : row.dataCfg.dataUnit.keyword }}
        </template>
      </el-table-column>
      <el-table-column :label="t('alarmManage.warningValue')" prop="value" width="80"/>
      <el-table-column :label="t('alarmManage.created')" prop="created"/>
      <el-table-column :label="t('alarmManage.confirmed')" prop="confirmed"/>
      <el-table-column :label="t('alarmManage.reparied')" prop="repaired"/>
      <el-table-column :label="t('alarmManage.frequency')" prop="frequency" width="80"/>
      <el-table-column :label="t('alarmManage.operations')" width="150">
        <template #default="scope">
          <el-button @click="alarm2remove = scope.row.id; dialog=true" plain type="warning" size="small" icon="delete">{{ t('alarmManage.delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="dialog">
      <h3>{{ t('alarmManage.deleteConfirmQuestion') }}</h3>
      <el-button @click="remove(alarm2remove)">{{ t('alarmManage.confirm') }}</el-button>
      <el-button @click="dialog = false">{{ t('alarmManage.cancel') }}</el-button>
    </el-dialog>
  </div>
  <div class="pagination">
    <el-pagination layout="total, sizes, prev, pager, next, jumper"
                   :current-page="page"
                   :page-size="pageSize"
                   :page-sizes="[25, 50, 75, 100]"
                   :total="total"
                   @current-change="handlePageChange"
                   @size-change="handleSizeChange"/>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue"
import {useStore} from "vuex"
import {formatTimeToStr} from "@/utils/date"
import {getAlarms, deleteAlarms} from "@/api/alarms";
import {ElMessage} from 'element-plus'
import {useI18n} from "vue-i18n"

const { t } = useI18n()

const url = '/api/v1/alarms'

const store = useStore()

const tableRef = ref()
const selection = ref([])

const tableData = ref([])
const total = ref(0)
const page = ref(1)
const pageSize = ref(25)

const handleSizeChange = function (value) {
  pageSize.value = value
  getTableData()
}

const handlePageChange = function (value) {
  page.value = value
  getTableData()
}

// 多选相关
function handleSelectionChange(val) {
  selection.value = val
}

function clearSelection() {
  tableRef.value.clearSelection()
}

function batchDelete(list) {
  let waits = []
  for (let s of list) {
    waits.push(removeAlarm(s.id))
  }
  Promise.all(waits)
      .then(() => {
        dialog.value = false
        getTableData()
      })
}

function deleteSelection() {
  batchDelete(selection.value)
}

function deleteInvalid() {
  let l = []
  for (let a of store.state.cables.deviceAlarms.list) {
    if (!a.dataCfg) {
      l.push(a)
    }
  }
  if (l.length < 1) {
    ElMessage({
      showClose: true,
      message: t('alarmManage.messageNoInvalidWarning'),
      type: "warning",
    })
  }
  batchDelete(l)
  store.dispatch('cables/request', true)
}

const getTableData = async function () {
  const table = await getAlarms({
    state: '3',
    page: page.value,
    pageSize: pageSize.value,
    ext: true
  })
  let tableD = []
  if (table.code === 0) {
    let devices = {}
    for (let alarm of table.data.list) {
      let deviceInfo = devices[alarm.devDeviceID]
      if (deviceInfo === undefined) {
        deviceInfo = devices[alarm.devDeviceID] = getDeviceInfo(parseInt(alarm.devDeviceID))
      }
      let valueUnit = alarm.dataCfg?.dataUnit?.unit
      if (!valueUnit) {
        valueUnit = ''
      }
      let valueLabel = alarm.dataCfg?.dataUnit?.name

      let value = alarm.value + valueUnit
      // let state = stateMap[alarm.state]
      let created = formatTimeToStr(alarm.createdat)
      let confirmed = ''
      if (!alarm.confirmedat.startsWith("0001-01-01")) {
        confirmed = formatTimeToStr(alarm.confirmedat)
      }
      let repaired = ''
      if (!alarm.repairedat.startsWith("0001-01-01")) {
        repaired = formatTimeToStr(alarm.repairedat)
      }
      tableD.push({
        ...alarm,
        ...deviceInfo,
        value,
        valueLabel,
        created,
        confirmed,
        repaired,
      })
    }
    tableData.value = tableD
    total.value = table.data.total
    page.value = table.data.page
    pageSize.value = table.data.pageSize
  }
}

const prepare = async function () {
  await store.dispatch('cables/request')
  await store.dispatch('companies/request')
}

const getDeviceInfo = function (deviceId) {
  let d = {}
  let device = store.getters['cables/getDeviceById'](deviceId)
  d.name = device.name
  if (device.model) {
    d.modelName = device.model.name
  } else {
    d.modelName = ''
  }
  let box = store.getters['cables/getDeviceById'](device.boxId)
  d.cable = store.getters['cables/getById'](box.cableId).name
  d.box = box.name
  d.company = store.getters['companies/getById'](device.companyId)?.name
  d.maintainer = store.getters['companies/getById'](device.maintenanceId)?.name
  d.manufacturer = store.getters['companies/getById'](device.manufacturerId)?.name
  d.cableAndBox = `${d.cable} - ${d.box}`
  return d
}

onMounted(
    () => {
      prepare()
          .then(() => getTableData()
          )
    }
)

const alarm2remove = ref(-1)
const dialog = ref(false)

function remove(id) {
  removeAlarm(id)
      .then(() => {
        dialog.value = false
        getTableData()
      })
}

function removeAlarm(id) {
  return deleteAlarms(id)
      .then((r) => {
        let typ = 'success'
        if (r.code !== 0) {
          typ = 'warning'
        }
        ElMessage({
          showClose: true,
          message: r.msg,
          type: typ
        })
      })
}

</script>

<style scoped>
.pagination {
  position: absolute;
  bottom: 30px;
  right: 50px;
}
</style>